import React from "react";
import { Button, Typography } from "antd";
import styled from "styled-components";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import {
  BGWrapper,
  BlueText,
  ButtonWrapper,
  HeaderText,
  HeroContent,
  HeroImage,
  HeroImageWrapper,
  HeroWrapper,
  LogoContainer,
  ParagraphText,
  SponsorLogo,
  SponsorSection,
  SponsorTitle,
  StyledButton,
} from "./Hero.style";
import { WebsiteContainer } from "ui/components/base";

export const StyledGhostButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 24px;
  width: 149px;
`;

const Hero = () => {
  return (
    <BGWrapper>
      <WebsiteContainer>
        <HeroWrapper>
          <HeroContent>
            <div>
              <HeaderText>
                <h2>
                  End-to-end event solution for all <a>your event </a> needs
                </h2>
              </HeaderText>
              <ParagraphText>
                <p>
                  Source for event vendors, manage your event registration, team, and marketing and all in one place.{" "}
                </p>
              </ParagraphText>
            </div>
          </HeroContent>
          <ButtonWrapper>
            <Link to={routeNames.authSignup}>
              <StyledButton>Create an event</StyledButton>
            </Link>
            <Link to={routeNames.event}>
              <StyledGhostButton>Explore Events</StyledGhostButton>
            </Link>
          </ButtonWrapper>
          <HeroImageWrapper>
            <HeroImage src="/LandingPage/header_content.svg" alt="hero-imge" />
          </HeroImageWrapper>
        </HeroWrapper>
        <SponsorSection>
          <SponsorTitle level={3}>Our sponsors and partners</SponsorTitle>
          <LogoContainer>
            <SponsorLogo src="/Techpoint-logo-500px-2 1.png"></SponsorLogo>
            <SponsorLogo src="/LandingPage/alticon.png"></SponsorLogo>
            <SponsorLogo src="/LandingPage/HeroIcon.png"></SponsorLogo>
            <SponsorLogo src="/image 4.png"></SponsorLogo>
          </LogoContainer>
        </SponsorSection>
      </WebsiteContainer>
    </BGWrapper>
  );
};

export default Hero;

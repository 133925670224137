export const nigeriaStatesCities = {
  Nigeria: {
    Fct: ["Abuja"],
    Abia: ["Umuahia", "Aba", "Ohafia"],
    Adamawa: ["Yola", "Mubi", "Jimeta"],
    "Akwa Ibom": ["Uyo", "Eket", "Ikot Ekpene"],
    Anambra: ["Awka", "Onitsha", "Nnewi"],
    Bauchi: ["Bauchi", "Azare", "Misau"],
    Bayelsa: ["Yenagoa", "Ogbia", "Amassoma"],
    Benue: ["Makurdi", "Gboko", "Otukpo"],
    Borno: ["Maiduguri", "Biu", "Bama"],
    "Cross River": ["Calabar", "Ugep", "Ikom"],
    Delta: ["Asaba", "Warri", "Sapele"],
    Ebonyi: ["Abakaliki", "Afikpo", "Onueke"],
    Edo: ["Benin City", "Auchi", "Uromi"],
    Ekiti: ["Ado-Ekiti", "Ikere-Ekiti", "Ijero-Ekiti"],
    Enugu: ["Enugu", "Nsukka", "Agbani"],
    Gombe: ["Gombe", "Kumo", "Billiri"],
    Imo: ["Owerri", "Orlu", "Okigwe"],
    Jigawa: ["Dutse", "Hadejia", "Gumel"],
    Kaduna: ["Kaduna", "Zaria", "Kafanchan"],
    Kano: ["Kano", "Wudil", "Gwarzo"],
    Katsina: ["Katsina", "Daura", "Funtua"],
    Kebbi: ["Birnin Kebbi", "Argungu", "Yauri"],
    Kogi: ["Lokoja", "Okene", "Idah"],
    Kwara: ["Ilorin", "Offa", "Omu-Aran"],
    Lagos: ["Lagos", "Ikeja", "Ikorodu"],
    Nasarawa: ["Lafia", "Keffi", "Akwanga"],
    Niger: ["Minna", "Bida", "Kontagora"],
    Ogun: ["Abeokuta", "Ijebu-Ode", "Sagamu"],
    Ondo: ["Akure", "Ondo", "Owo"],
    Osun: ["Osogbo", "Ile-Ife", "Ilesa"],
    Oyo: ["Ibadan", "Ogbomosho", "Oyo"],
    Plateau: ["Jos", "Pankshin", "Shendam"],
    Rivers: ["Port Harcourt", "Bonny", "Eleme"],
    Sokoto: ["Sokoto", "Birnin Kebbi", "Gwadabawa"],
    Taraba: ["Jalingo", "Wukari", "Gembu"],
    Yobe: ["Damaturu", "Potiskum", "Nguru"],
    Zamfara: ["Gusau", "Kaura Namoda", "Talata Mafara"],
  },
};

import React, { useEffect } from "react";
import { Outlet, Route, Routes, ScrollRestoration, useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import Hotjar from "@hotjar/browser";

import Header from "app/pages/website/header/Header";
import Footer from "app/pages/website/footer/footer";
import { routeNames } from "lib/routes";
import Auth from "auth";
import LoginPage from "app/pages/authentication/Login";
import Signup from "app/pages/authentication/Register";

const MIXPANEL_TOKEN: unknown = process.env.REACT_APP_MIXPANEL_TOKEN;
const HOTJAR_SITE_ID: unknown = process.env.REACT_APP_HOTJAR_ID;

const siteId = HOTJAR_SITE_ID;
const hotjarVersion = 6;

// Near entry of your product, init Mixpanel

function Website() {
  // const location = useLocation();
  // const state = location.state as { backgroundLocation?: Location };

  useEffect(() => {
    Hotjar.init(siteId as number, hotjarVersion);
    mixpanel.init(MIXPANEL_TOKEN as string, { debug: true, track_pageview: true, persistence: "localStorage" });
  }, []);
  return (
    <>
      <Header />
      <Outlet />
      <ScrollRestoration />
      <Footer />
      {/* {state?.backgroundLocation && (
        <Routes>
          <Route path={routeNames.authLogin} element={<Auth />}>
            <Route index element={<LoginPage />} />
          </Route>
          <Route path={routeNames.authSignup} element={<Auth />}>
            <Route index element={<Signup />} />
          </Route>
        </Routes>
      )} */}
    </>
  );
}
export default Website;

import React from "react";
import styled from "styled-components";
import { Button } from "antd";

const AboutUs = styled.div`
  background-image: url("/LandingPage/HeroImage.jpg");
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
  @media (max-width: 700px) {
    height: 1000px;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
// the badge group section
const Badge = styled.div`
  display: flex;
  max-width: 170px;
  height: 100%;
  border-radius: 10px;
  border: 2px solid #fff;
  gap: 12px;
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  p {
    font-weight: 1.5rem;
    font-size: 1.5rem;
    line-height: 1rem;
    color: rgba(52, 64, 84, 1);

    @media (max-width: 650px) {
      font-size: 1.2rem;
      font-weight: 1.2rem;
      line-height: 0;
    }
  }
`;
const BadgeGroup = styled.div`
  width: 100%;
  height: 28px;
  border-radius: 6px;
  border: 2px solid #fff;
  padding: 2px;
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BadgeGroupImg = styled.img``;

const AboutContent = styled.div`
  max-width: 979px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Paragraph = styled.p`
  max-width: 853px;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  color: rgba(71, 84, 103, 1);
`;
const HeaderText = styled.h2`
  max-width: 979px;
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  color: rgba(16, 24, 40, 1);
`;

//sponsor section
const SponsorSection = styled.section`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  text-align: center;
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 44px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
const SponsorLogo = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
`;
//Sub Hero section
const SubHeroContainer = styled.div`
  margin: 16px;
`;
const SubContainer = styled.div`
  max-width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const LeftContent = styled.div`
  margin-bottom: 20px;
`;

const RightContent = styled.div`
  height: 46px;
`;
const StyledButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 40px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  margin-right: 10px;
  width: 190px;
`;
const SubHeaderText = styled.h1`
  span {
    color: rgba(19, 144, 230, 1);
  }
`;
const AboutImg = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    padding: 10px;
    height: 200px;
  }
`;
export {
  AboutUs,
  Section,
  Badge,
  BadgeGroupImg,
  BadgeGroup,
  Paragraph,
  HeaderText,
  AboutContent,
  SponsorSection,
  LogoContainer,
  SponsorLogo,
  SubHeroContainer,
  SubContainer,
  LeftContent,
  RightContent,
  StyledButton,
  SubHeaderText,
  AboutImg,
};

import React from "react";
import styled from "styled-components";
import { WebsiteContainer, AppBadge } from "ui/components/base";
import { routeNames } from "lib/routes";
import { StyledButton } from "./ServicesHomePage";
import {
  Icon,
  MainFrame,
  SubFrame,
  SubFrameHeader,
  SubFrameIcon,
  SubFrameText,
  LearnMore,
  SubFrameParagraph,
} from "./eventTicketing";
import { devices } from "ui/theme";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 2.5rem;
  color: rgba(18, 18, 18, 1);
`;
const ContentText = styled.p`
  max-width: 512px;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(47, 47, 47, 1);
  line-height: 24px;
`;
const Section = styled.div`
  max-width: 100%;
  height: auto;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 0 20px;
`;
const FirstContent = styled.div`
  max-width: 100%;
  height: auto;
  padding: 0px 16px;
  align-items: center;
  flex-direction: column;
  float: left;
  justify-content: flex-start;
  margin-top: 70px;
  flex: 1;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;
const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;
const MarketingHeader = styled.h2`
  font-weight: 500;
  font-size: 2.5rem;
  color: rgba(18, 18, 18, 1);
  margin-top: -5px;
  margin-bottom: -5px;
`;
const SecondContent = styled.img`
  display: none;
  width: 100%;
  max-width: 576px;
  height: auto;
  max-height: 720px;
  @media ${devices.tablet} {
    display: block;
  }
`;
const MobileContent = styled.img`
  display: block;
  max-width: 100%;
  @media ${devices.tablet} {
    display: none;
  }
`;

const EventMarketing = () => {
  const servicesLink = [
    {
      title: "Event Financing",
      description: "Find sources for Financing your event",
      icon: "/LandingPage/HeroIcon_1.png",
      link: routeNames.financialServices,
    },
    {
      title: "Ticketing",
      description: "Manage your event ticketing and registration with ease",
      icon: "/servicesImages/EventTicketingIcon.png",
      link: routeNames.ticketingPage,
    },
    {
      title: "Analytics",
      description: "Collect insights from your events and use them to make data-driven decisions",
      icon: "/LandingPage/HeroIcon_3.png",
      link: routeNames.analysisPage,
    },
    {
      title: "Event Managemant",
      description: "Manage your team, stakeholders, and vendors,as well as multiple events",
      icon: "/LandingPage/HeroIcon_5.png",
      link: routeNames.eventManagement,
    },
    {
      title: "Vendor Sourcing",
      description: "FInd the vendors you need to make your event successful",
      icon: "/LandingPage/HeroIcon_6.png",
      link: routeNames.eventVendorSourcing,
    },
  ];
  return (
    <>
      <WebsiteContainer>
        <Header>
          <AppBadge group="Services"></AppBadge>
          <HeaderText>Event Marketing</HeaderText>
          <StyledButton>Get Started</StyledButton>
        </Header>
        <Section>
          <ContentBody>
            <FirstContent>
              <Icon src="/servicesImages/MarketingIcon_1.png" />
              <MarketingHeader>Marketing Tools</MarketingHeader>
              <ContentText>
                Make use of our marketing tools on the platform to reach a wider audience with your events. With
                Evendy&apos;s built-in marketing tools, you can promote your event and reach the perfect attendees
                without the usual hassles. Whether you&apos;re hosting a local meetup or global convention, Evendy
                ensures you get your event across to the right audience through smart, streamlined marketing
                capabilities.{" "}
              </ContentText>
            </FirstContent>
            <SecondContent src="/servicesImages/MarketingImg_1.png" alt="Marketing image 1" />
            <MobileContent src="/servicesImages/MarketingMobile_1.png" alt="marketing mobile image" />
          </ContentBody>
          <ContentBody>
            <SecondContent src="/servicesImages/MarketingImg_2.png" />

            <FirstContent>
              <Icon src="/servicesImages/MarketingIcon_2.png" />
              <MarketingHeader>Event Partners</MarketingHeader>
              <ContentText>
                We can help you partner with various marketing partners on our platform to help you achieve the best for
                your upcoming event. Our partners are sourced strictly and well vetted. Promoting an event is a massive
                undertaking, you can tap into our pre-vetted network of marketing partners to amplify your reach. From
                influencers and bloggers to ad platforms, we&apos;ve hand-picked diverse channels to exponentially
                increase awareness. Filter through partners based on your criteria like location and budget. We&apos;ll
                coordinate everything - briefs, materials, pricing, and real-time performance tracking - all in one
                place.
              </ContentText>
            </FirstContent>
            <MobileContent src="/servicesImages/MarketingMobile_2.png" alt="marketing mobile image" />
          </ContentBody>
        </Section>

        <MainFrame>
          {servicesLink.map((content, index) => (
            <SubFrame key={index}>
              <SubFrameIcon>
                <Icon src={content.icon} />
              </SubFrameIcon>
              <SubFrameHeader>
                <SubFrameText>{content.title}</SubFrameText>
              </SubFrameHeader>
              <SubFrameParagraph>{content.description}</SubFrameParagraph>
              <LearnMore to={content.link}>Learn more</LearnMore>
            </SubFrame>
          ))}
        </MainFrame>
      </WebsiteContainer>
    </>
  );
};
export default EventMarketing;

import React from "react";
import styled from "styled-components";
import { WebsiteContainer, AppBadge } from "ui/components/base";
import { StyledButton } from "./ServicesHomePage";
import { routeNames } from "lib/routes";
import {
  Icon,
  MainFrame,
  SubFrame,
  SubFrameHeader,
  SubFrameIcon,
  SubFrameText,
  LearnMore,
  SubFrameParagraph,
} from "./eventTicketing";
import { devices } from "ui/theme";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 2.5rem;
  color: rgba(18, 18, 18, 1);
  margin-bottom: -10px;
`;
const HeaderParagraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: rgba(47, 47, 47, 1);
  line-height: 1.5rem;
`;
const FirstContent = styled.div`
  flex: 1;
  padding: 0;
  margin-top: 5%;
`;
const SecondContent = styled.img`
  max-width: 720px;
  max-height: 560px;
  display: none;
  @media ${devices.mobileL} {
    display: block;
  }
`;
const MobileSecondCOntent = styled.img`
  display: block;
  max-width: 100%;
  @media ${devices.mobileL} {
    display: none;
  }
`;
const ContentBody = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 10px;
  @media ${devices.mobileL} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    width: 100%;
  }
`;

const EventManagement = () => {
  const servicesLink = [
    {
      title: "Event Financing",
      description: "Find sources for Financing your event",
      icon: "/LandingPage/HeroIcon_1.png",
      link: routeNames.financialServices,
    },
    {
      title: "Ticketing",
      description: "Manage your event ticketing and registration with ease",
      icon: "/servicesImages/EventTicketingIcon.png",
      link: routeNames.ticketingPage,
    },
    {
      title: "Analytics",
      description: "Collect insights from your events and use them to make data-driven decisions",
      icon: "/LandingPage/HeroIcon_3.png",
      link: routeNames.analysisPage,
    },
    {
      title: "Event Marketing",
      description: "Manage your event marketing processmore efficiently with our tools",
      icon: "/LandingPage/HeroIcon_4.png",
      link: routeNames.eventMarketing,
    },
    {
      title: "Vendor Sourcing",
      description: "FInd the vendors you need to make your event successful",
      icon: "/LandingPage/HeroIcon_6.png",
      link: routeNames.eventVendorSourcing,
    },
  ];
  return (
    <>
      <WebsiteContainer>
        <Header>
          <AppBadge group="Services"></AppBadge>
          <HeaderText>Event Management</HeaderText>
          <HeaderParagraph>Centralized tools to keep your event & team aligned</HeaderParagraph>
          <StyledButton>Get Started</StyledButton>
        </Header>

        <ContentBody>
          <FirstContent>
            <Icon src="/LandingPage/HeroIcon_5.png" />
            <HeaderText>Event Management</HeaderText>
            <HeaderParagraph>
              Discover the power of Evendy&apos;s event management features designed to streamline every aspect of your
              event planning journey. From vendor sourcing and management to team coordination and attendee
              registration, our intuitive platform offers a seamless experience for both organizers and vendors. Easily
              find and connect with trusted vendors, manage event logistics with precision, and leverage our marketing
              tools to promote your event effectively. Say goodbye to stress and hello to seamless event management with
              Evendy.
            </HeaderParagraph>
          </FirstContent>
          <SecondContent src="/servicesImages/EventManagent.png" />
          <MobileSecondCOntent src="/servicesImages/marketing_Mobile.png" alt="management mobile image" />
        </ContentBody>

        <MainFrame>
          {servicesLink.map((content, index) => (
            <SubFrame key={index}>
              <SubFrameIcon>
                <Icon src={content.icon} />
              </SubFrameIcon>
              <SubFrameHeader>
                <SubFrameText>{content.title}</SubFrameText>
              </SubFrameHeader>
              <SubFrameParagraph>{content.description}</SubFrameParagraph>
              <LearnMore to={content.link}>Learn more</LearnMore>
            </SubFrame>
          ))}
        </MainFrame>
      </WebsiteContainer>
    </>
  );
};

export default EventManagement;

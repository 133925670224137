import React from "react";
import styled from "styled-components";
import { WebsiteContainer, AppBadge } from "ui/components/base";
import { StyledButton } from "./ServicesHomePage";
import { routeNames } from "lib/routes";
import {
  MainFrame,
  SubFrame,
  SubFrameHeader,
  SubFrameIcon,
  SubFrameText,
  LearnMore,
  SubFrameParagraph,
} from "./eventTicketing";
import { devices } from "ui/theme";

const Header = styled.div`
  display: flex;
  padding-bottom: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    padding-bottom: 20px;
  }
`;
const Text = styled.div`
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
`;
const HeaderText = styled.h2`
  max-width: 889px;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: rgba(18, 18, 18, 1);
`;
const HeaderParagraph = styled.p`
  max-width: 368px;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center;
  margin-top: -20px;
  color: rgba(47, 47, 47, 1);
`;
const AnalysisText = styled.p`
  width: 397px;
  height: 120px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgba(47, 47, 47, 1);
  margin-top: -15px;
`;
const LeftContainer = styled.img`
  max-width: 100%;
  margin-bottom: 20px;
`;
const RightContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 5%;
  @media (max-width: 650px) {
    padding: 5px;
    margin-top: 0;
  }
`;
const Icon = styled.img`
  margin-bottom: -10px;
`;
const ContentBody = styled.div`
  display: flex;
  flex-direction: column;

  @media ${devices.mobileL} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const EventAnalysis = () => {
  const servicesLink = [
    {
      title: "Event Financing",
      description: "Find sources for Financing your event",
      icon: "/LandingPage/HeroIcon_1.png",
      link: routeNames.financialServices,
    },
    {
      title: "Ticketing",
      description: "Manage your event ticketing and registration with ease",
      icon: "/servicesImages/EventTicketingIcon.png",
      link: routeNames.ticketingPage,
    },
    {
      title: "Event Marketing",
      description: "Manage your event marketing processmore efficiently with our tools",
      icon: "/LandingPage/HeroIcon_4.png",
      link: routeNames.eventMarketing,
    },
    {
      title: "Event Managemant",
      description: "Manage your team, stakeholders, and vendors,as well as multiple events",
      icon: "/LandingPage/HeroIcon_5.png",
      link: routeNames.eventManagement,
    },
    {
      title: "Vendor Sourcing",
      description: "FInd the vendors you need to make your event successful",
      icon: "/LandingPage/HeroIcon_6.png",
      link: routeNames.eventVendorSourcing,
    },
  ];
  return (
    <>
      <WebsiteContainer>
        <Header>
          <Text>
            <AppBadge group="Services"></AppBadge>
            <HeaderText>Event Analysis</HeaderText>
            <HeaderParagraph>
              Collect insights from your guest and use them to make data driven decisions
            </HeaderParagraph>
          </Text>
          <StyledButton>Get started</StyledButton>
        </Header>

        <ContentBody>
          <LeftContainer src="/servicesImages/event_analysis.png" alt="event analysis page" />{" "}
          <RightContainer>
            <Icon src="/LandingPage/HeroIcon_3.png" alt="event analysis icon" />
            <HeaderText>Event Analytics</HeaderText>
            <AnalysisText>
              Empower your event planning process with Evendy&apos;s data-driven insights, enabling you to make informed
              and strategic decisions. With Evendy, harness the power of data to optimize every aspect of your event and
              ensure its success.
            </AnalysisText>
          </RightContainer>
        </ContentBody>
        <MainFrame>
          {servicesLink.map((content, index) => (
            <SubFrame key={index}>
              <SubFrameIcon>
                <Icon src={content.icon} />
              </SubFrameIcon>
              <SubFrameHeader>
                <SubFrameText>{content.title}</SubFrameText>
              </SubFrameHeader>
              <SubFrameParagraph>{content.description}</SubFrameParagraph>
              <LearnMore to={content.link}>Learn more</LearnMore>
            </SubFrame>
          ))}
        </MainFrame>
      </WebsiteContainer>
    </>
  );
};
export default EventAnalysis;

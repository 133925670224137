import styled, { RuleSet, css } from "styled-components";
import { Button } from "antd";
import { screenSizes } from "ui/theme";
import { AppLink } from "ui/components/base";
import { Link } from "react-router-dom";

export const HEADER_HEIGHT = "83px";

const nonMobileOnlyFlex = (desktopStyles: RuleSet<object>) => `
  display: none;
  @media (min-width: ${screenSizes.laptop}) {
    display: flex;
    ${desktopStyles}
  }
`;
const mobileOnlyFlex = (mobileStyles: RuleSet<object>) => `
  display: flex;
  ${mobileStyles}
  @media (min-width: ${screenSizes.laptop}) {
    display: none;
  }
`;

const hiddenOnMobile = css`
  visibility: hidden;
  @media (min-width: ${screenSizes.laptop}) {
    visibility: visible;
  }
`;

const hiddenOnNonMobile = css`
  visibility: visible;
  @media (min-width: ${screenSizes.laptop}) {
    visibility: hidden;
  }
`;
const hidden = `
  visibility: hidden;
`;

export const SiteHeader = styled.header`
  position: fixed;
  background-color: white;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${HEADER_HEIGHT};
  max-width: 100vw;
  z-index: 999;
  min-height: 83px;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const HeaderPlaceholder = styled.div`
  height: ${HEADER_HEIGHT};
`;

export const LogoSection = styled.div`
  display: flex;
  flex: 1;
`;

export const MobileNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT};
  padding: 0px 88px;
  @media (min-width: ${screenSizes.laptop}) {
    display: none;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT};
`;

export const NavMenu = styled.ul`
  ${nonMobileOnlyFlex(css`
    display: flex;
    flex: 1;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
  `)}
`;
export const MobileNavMenu = styled.ul`
  ${mobileOnlyFlex(css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style-type: none;
    gap: 1rem;
    flex: 1;
  `)}
`;
export const StyledLi = styled.li`
  text-decoration: none;
  outline: none;
  list-style-type: none;
`;
export const Login = styled.button`
  height: 46px;
  border: none;
  color: #1390e6;
  background-color: #fff;
  cursor: pointer;
  padding: 12px 30px;
  width: 190px;
`;
export const LoginLink = styled(Link)`
  all: unset;
  height: 46px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #1390e6;
  background-color: #fff;
  cursor: pointer;
  width: 190px;
  border-radius: 8px;
  text-decoration: none;
  &:hover {
    background-color: rgba(180, 180, 180, 0.1);
  }
`;

export const StyledButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 30px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 190px;
`;
export const SignupLink = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 190px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(180, 180, 180, 0.1);
    color: rgba(19, 144, 230, 1);
  }
`;

export const StyledHeaderLink = styled(AppLink)`
  text-decoration: none;
  outline: none;
  color: #121212;
  border: none;
  padding: 10px;
`;
export const RightAlign = styled.div`
  display: flex;
`;
export const CollaspeButton = styled.button`
  ${mobileOnlyFlex(css`
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
  `)}
`;

export const AuthSection = styled.div`
  gap: 8px;
  ${nonMobileOnlyFlex(css`
    flex: 1;
    ${hiddenOnMobile}
    ${hidden}
  `)}
`;
export const MobileAuthSection = styled.div`
  flex-direction: column;
`;

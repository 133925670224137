import { Button } from "antd";
import styled, { css } from "styled-components";
import { screenSizes } from "ui/theme";

export const SubHeroContainer = styled.div`
  padding: 56px 16px;

  @media (min-width: ${screenSizes.laptop}) {
    padding: 56px 88px;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RightContent = styled.div`
  height: 46px;
`;

export const SubHeaderText = styled.h3`
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  text-align: left;

  margin: 0;
`;

export const SubParagraphText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;

  margin: 0;
`;

export const StyledButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 40px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  margin-right: 10px;
  width: 190px;
`;
export const MainFrame = styled.div`
  max-width: 100%;
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  /* margin: 38px; */
  flex-direction: column;
  @media (min-width: ${screenSizes.laptop}) {
  }
  @media (max-width: 768px) {
  }
`;

export const SubFrameHeader = styled.div`
  display: flex;
  align-items: center;
`;
export const SubFrameText = styled.h2`
  margin: 0;
  width: 100%;

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: left;
`;
export const SubFrameDetail = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  color: #2f2f2f;
`;
export const LearnMore = styled.a`
  display: block;
  margin-top: auto;
  color: #1390e6;
  text-decoration: none;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
`;

const mobileScreen = "768px";
const mobileStyle = css`
  max-width: 100%;
  flex-direction: column;
`;

export const SubFrameResponsive = styled.div`
  width: 100%;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 1);
  &:hover {
    transform: scale(0.95);
    transition: 1s;
    background-color: rgba(240, 240, 240, 1);
  }
  @media (max-width: ${mobileScreen}) {
    ${mobileStyle}
  }
`;

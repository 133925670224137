import styled from "styled-components";
import { screenSizes } from "ui/theme";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 56px auto;
  width: 100%;
  gap: 38px;
`;

export const BlueText = styled.span`
  color: rgba(19, 144, 230, 1);
`;
export const Compartment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  max-width: 100%;
  height: auto;
  position: relative;
  gap: 8px;
  @media screen and (min-width: ${screenSizes.tablet}) {
    padding: 0 88px;
    width: 100%;
  }
`;
export const Header = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  text-align: left;
  margin: 0;
`;
export const Paragraph = styled.p`
  color: #2f2f2f;
  margin-top: -10px;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
`;

export const TestimonialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  justify-content: space-between;
  border-radius: 24px;
  background-color: #f9fafb;
  margin: 0 auto;

  @media screen and (min-width: ${screenSizes.laptop}) {
    flex-direction: row;
    max-height: 464px;
  }
`;

export const TestimonialContentWrapper = styled.div`
  display: block;
`;

export const CompartmentA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: flex;
  gap: 32px;
  position: relative;
  width: 100%;
  max-width: 311px;
  height: auto;
  max-height: 100%;
  padding: 64px 16px;
  @media (min-width: ${screenSizes.laptop}) {
    padding: 64px;
    max-width: 656px;
    max-height: 306px;
  }
`;

export const CompartmentB = styled.div`
  display: flex;
  width: auto;
`;

export const TestimonialImage = styled.img`
  border-radius: 0 24px 24px 0;
  max-width: 100%;
  max-height: 464px;
  height: auto;
  margin-bottom: 0.5rem;
  border-radius: 0px 0px 24px 24px;
  @media (min-width: ${screenSizes.mobileL}) {
    width: 480px;
  }
  @media (min-width: ${screenSizes.laptop}) {
    border-radius: 0px 24px 24px 0;
  }
`;

export const IconRow = styled.div`
  display: flex;
`;
export const Icon = styled.div`
  width: 20px;
  height: 20px;
  background-image: url("/LandingPage/TestimonialIcon.png");
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Quote = styled.div`
  color: #101828;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  max-width: 100%;
  height: auto;

  /* max-height: 120px; */
`;
export const MainText = styled.div`
  color: #101828;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: left;
`;

export const SubText = styled.div`
  color: #667085;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
`;

export const PaginationDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(19, 144, 230, 1);
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
`;
export const PaginationWrapper = styled.div`
  display: flex;
  width: 60px;
  height: 10px;
  gap: 16px;
`;

import React from "react";
import { AppBadge, WebsiteContainer } from "ui/components/base";
import {
  BenefitsContainerContent,
  BenefitsImage,
  BenefitsLeftContainer,
  BenefitsLeftContainerHeader,
  BenefitsLeftContainerParagraph,
  CounterSection,
  Icon,
  Text,
} from "./Benefits.style";

//this is for the section beneath the counter app

const CounterComponent: React.FC = () => {
  return (
    <WebsiteContainer>
      <CounterSection>
        <BenefitsLeftContainer>
          <AppBadge group="Benefits">
            <p>The Great Benefit we offer</p>
          </AppBadge>
          <BenefitsLeftContainerHeader>Great benefits you get with us</BenefitsLeftContainerHeader>
          <BenefitsLeftContainerParagraph>Get Some insight into what you gain</BenefitsLeftContainerParagraph>
          <BenefitsContainerContent>
            <Icon src="/LandingPage/benefit_icon.png" />
            <Text>Get trusted vendors for your event</Text>
          </BenefitsContainerContent>
          <BenefitsContainerContent>
            <Icon src="/LandingPage/benefit_icon.png" />
            <Text>All in one streamlined tools for planning & collaboration</Text>
          </BenefitsContainerContent>
          <BenefitsContainerContent>
            <Icon src="/LandingPage/benefit_icon.png" />
            <Text>Create great experience with ease</Text>
          </BenefitsContainerContent>
        </BenefitsLeftContainer>
        <BenefitsImage src="/LandingPage/benefit_image.svg" alt="great benefit you enjoy with us image" />
      </CounterSection>
    </WebsiteContainer>
  );
};
export default CounterComponent;

import React from "react";
import styled from "styled-components";
import { Form, Input, notification } from "antd";
import { ReactComponent as NotificationImg } from "images/vendor/Alert.svg";
import { Container, Label } from "./general";
import { SubmitContainer } from "../AccountDetails";
import SubmitButton from "../Button";
import { useEditVendorBank } from "lib/hooks/vendor/useVendorBankById";
import { useGetUser } from "lib/hooks";

const Alert = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  margin-bottom: 20px;
`;

const BankDetails = () => {
  const [form] = Form.useForm();
  const { data: user } = useGetUser();
  const { mutate: editVendorBank } = useEditVendorBank();

  const handleSubmit = (data: any) => {
    if (!user) {
      notification.warning({ message: "Fetching user data... kindly try again shortly" });
      return;
    }

    data.vendorEmail = user.email;

    editVendorBank(data, {
      onSuccess: () => {
        notification.success({
          message: "Success",
          description: "Bank details saved successfully.",
        });
        form.resetFields();
      },
      onError: error => {
        console.error("Error submitting form:", error);
        notification.error({
          message: "Error",
          description: "Failed to save bank details.",
        });
      },
    });
  };

  return (
    <>
      <Alert>
        <NotificationImg /> <p>Please ensure all details are correct</p>
      </Alert>
      <Container>
        <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={{ remember: true }}>
          <Form.Item
            name="bankName"
            //rules={[{ required: true, message: "Please enter your bank name" }]}
          >
            <Label>Bank Name</Label>
            <Input
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              placeholder="Enter your bank name"
            />
          </Form.Item>
          <Form.Item
            name="bankAccountName"
            //rules={[{ required: true, message: "Please enter your bank account name" }]}
          >
            <Label>Account Name</Label>
            <Input
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              placeholder="Enter your bank account name"
            />
          </Form.Item>
          <Form.Item
            name="bankAccountNumber"
            //rules={[{ required: true, message: "Please enter your bank account number" }]}
          >
            <Label>Account Number</Label>
            <Input
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              placeholder="Enter your bank account number"
            />
          </Form.Item>
          <Form.Item
            name="bankCode"
            //rules={[{ required: true, message: "Please enter your bank code" }]}
          >
            <Label>Bank Code</Label>
            <Input
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              placeholder="Enter your bank code"
            />
          </Form.Item>
          <Form.Item>
            <SubmitContainer>
              <SubmitButton form={form}>Save Changes</SubmitButton>
            </SubmitContainer>
          </Form.Item>
        </Form>
      </Container>
    </>
  );
};

export default BankDetails;

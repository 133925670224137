import { routeNames } from "lib/routes";

export const subframeContent = [
  {
    title: "Event Financing",
    description: "Find sources for Financing your event",
    icon: "LandingPage/HeroIcon_1.png",
    link: routeNames.financialServices,
  },
  {
    title: "Ticketing",
    description: "Managing your event registration and ticketing with ease",
    icon: "LandingPage/HeroIcon_2.png",
    link: routeNames.ticketingPage,
  },
  {
    title: "Analytics",
    description: "Collect insights from your events and use them to make data-driven decisions",
    icon: "LandingPage/HeroIcon_3.png",
    link: routeNames.analysisPage,
  },
  {
    title: "Event Marketing",
    description: "Manage your event marketing processmore efficiently with our tools",
    icon: "LandingPage/HeroIcon_4.png",
    link: routeNames.eventMarketing,
  },
  {
    title: "Event Managemant",
    description: "Manage your team, stakeholders, and vendors,as well as multiple events",
    icon: "LandingPage/HeroIcon_5.png",
    link: routeNames.eventManagement,
  },
  {
    title: "Vendor Sourcing",
    description: "Find the vendors you need to make your event successful",
    icon: "LandingPage/HeroIcon_6.png",
    link: routeNames.eventVendorSourcing,
  },
];

import React from "react";
import styled from "styled-components";
import { HeaderText, HeaderParagraph, StyledButton } from "./ServicesHomePage";
import { WebsiteContainer, AppBadge } from "ui/components/base";
import { devices } from "ui/theme";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";

export const MainFrame = styled.div`
  max-width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: wrap;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 700px;
  }
`;
export const SubFrame = styled.div`
  flex: 1;
  max-width: 240px;
  gap: 24px;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px;
  margin: 0;
  &:hover {
    transform: scale(0.95);
    transition: 1s;
    background-color: rgba(240, 240, 240, 1);
    border-radius: 8px;
  }
`;
export const SubFrameHeader = styled.div`
  display: flex;
  align-items: center;
`;
export const SubFrameText = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: rgba(18, 18, 18, 1);
  margin-top: 20px;
  margin-bottom: -10px;
`;
export const SubFrameParagraph = styled.p`
  width: 240px;
  height: 80px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const SubFrameIcon = styled.div``;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
`;
export const TicketingContainer = styled.div`
  max-width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: -90px;
`;
const TicketingImage = styled.img`
  height: auto;
  border-radius: 8px;
  padding: 20px;
  max-width: 100%;
  display: none;
  @media ${devices.mobileL} {
    display: block;
  }
`;
const MobileTicketingImage = styled.img`
  display: block;
  max-width: 100%;
  padding-top: 20px;
  @media ${devices.mobileL} {
    display: none;
  }
`;
const Ticketing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  max-height: auto;
  padding: 10px 32px;
  margin-bottom: 50px;
`;
export const LearnMore = styled(Link)`
  display: block;
  margin-top: auto;
  color: rgba(19, 144, 230, 1);
  text-decoration: none;
`;
export const Icon = styled.img``;

const TicketingPage = () => {
  const servicesLink = [
    {
      title: "Event Financing",
      description: "Find sources for Financing your event",
      icon: "/LandingPage/HeroIcon_1.png",
      link: routeNames.financialServices,
    },
    {
      title: "Analytics",
      description: "Collect insights from your events and use them to make data-driven decisions",
      icon: "/LandingPage/HeroIcon_3.png",
      link: routeNames.analysisPage,
    },
    {
      title: "Event Marketing",
      description: "Manage your event marketing processmore efficiently with our tools",
      icon: "/LandingPage/HeroIcon_4.png",
      link: routeNames.eventMarketing,
    },
    {
      title: "Event Managemant",
      description: "Manage your team, stakeholders, and vendors,as well as multiple events",
      icon: "/LandingPage/HeroIcon_5.png",
      link: routeNames.eventManagement,
    },
    {
      title: "Vendor Sourcing",
      description: "FInd the vendors you need to make your event successful",
      icon: "/LandingPage/HeroIcon_6.png",
      link: routeNames.eventVendorSourcing,
    },
  ];
  return (
    <>
      <WebsiteContainer>
        <TicketingContainer>
          <Text>
            <AppBadge group="Services"></AppBadge>
            <HeaderText>Event Ticketing</HeaderText>
            <HeaderParagraph>Manage your event ticketing and registration with ease</HeaderParagraph>
          </Text>
          <StyledButton>Get Started</StyledButton>
          <TicketingImage src="/servicesImages/servicesTicketing.png" alt="services-ticketing-image" />
          <MobileTicketingImage src="/servicesImages/ticketing_mobile.png" alt="mobile ticketing image" />

          <Ticketing>
            <Icon src="/servicesImages/EventTicketingIcon.png" alt="services-ticketing-icon" />
            <HeaderText>Event Ticketing</HeaderText>
            <HeaderParagraph>
              Kick things off on the right foot with a seamless registration experience. Have all your attendees
              register for your event with no hassle and complications using our platform.
            </HeaderParagraph>
          </Ticketing>
        </TicketingContainer>
      </WebsiteContainer>
      <MainFrame>
        {servicesLink.map((content, index) => (
          <SubFrame key={index}>
            <SubFrameIcon>
              <Icon src={content.icon} />
            </SubFrameIcon>
            <SubFrameHeader>
              <SubFrameText>{content.title}</SubFrameText>
            </SubFrameHeader>
            <SubFrameParagraph>{content.description}</SubFrameParagraph>
            <LearnMore to={content.link}>Learn more</LearnMore>
          </SubFrame>
        ))}
      </MainFrame>
    </>
  );
};

export default TicketingPage;

import React from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import EventDetail from "./eventdetail";

const Top = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: rgba(18, 18, 18, 1);
  width: 100%;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    padding: 10px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const ReturnButton = styled(Link)`
  text-decoration: none;
  color: #1390e6;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;

  @media (max-width: 768px) {
    gap: 30px;
  }

  @media (max-width: 480px) {
    gap: 20px;
    padding: 10px;
  }
`;

const { TabPane } = Tabs;

const TabContainer = styled(Tabs)`
  width: 100%;
  display: flex;
  justify-content: center;

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-list {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  @media (max-width: 768px) {
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    .ant-tabs-nav .ant-tabs-tab {
      font-size: 12px;
    }
  }
`;

const NewEvent = () => {
  return (
    <Container>
      <ReturnButton to={routeNames.organizerHome}>
        <LeftOutlined style={{ color: "#1390e6" }} /> Back
      </ReturnButton>
      <Top>
        Create Event
        <TabContainer defaultActiveKey="1">
          <TabPane tab="Event Details" key="1">
            <EventDetail />
          </TabPane>
        </TabContainer>
      </Top>
    </Container>
  );
};

export default NewEvent;

import React, { useState } from "react";
import {
  SiteHeader,
  Nav,
  NavMenu,
  Login,
  CollaspeButton,
  StyledLi,
  RightAlign,
  StyledButton,
  StyledHeaderLink,
  HeaderPlaceholder,
  MobileNav,
  MobileNavMenu,
  AuthSection,
  MobileAuthSection,
  LogoSection,
  LoginLink,
  SignupLink,
} from "./Header.style";
import { Drawer, Dropdown, Menu, Space } from "antd";
import { MenuOutlined, DownOutlined, MailTwoTone } from "@ant-design/icons";
import { AppButton, Menu02, WebsiteContainer } from "ui/components/base";
import { useGetUser } from "lib/hooks/auth/useGetUser";
import { routeNames } from "lib/routes";
import { Link, useLocation } from "react-router-dom";
import { getSession, getUserHomeRoute } from "lib/utils";

const Header = () => {
  const [showLinks, setShowLinks] = useState<boolean>(false);

  const { data: user } = useGetUser();
  const userHome = getUserHomeRoute(user);

  const location = useLocation();

  const toggleLinks = () => {
    setShowLinks(!showLinks);
    console.log("showLinks:", showLinks);
  };

  const servicesSubMenu = (
    <Menu style={{ padding: "10px" }}>
      <Menu.Item key="0">
        <StyledHeaderLink to={routeNames.financialServices}>Event Finance</StyledHeaderLink>
      </Menu.Item>
      <Menu.Item key="1">
        <StyledHeaderLink to={routeNames.ticketingPage}>Event Ticketing</StyledHeaderLink>
      </Menu.Item>
      <Menu.Item key="2">
        <StyledHeaderLink to={routeNames.analysisPage}>Event Anaylsis</StyledHeaderLink>
      </Menu.Item>
      <Menu.Item key="3">
        <StyledHeaderLink to={routeNames.eventManagement}>Event Management</StyledHeaderLink>
      </Menu.Item>
      <Menu.Item key="4">
        <StyledHeaderLink to={routeNames.eventMarketing}>Event Marketing</StyledHeaderLink>
      </Menu.Item>
      <Menu.Item key="5">
        <StyledHeaderLink to={routeNames.eventVendorSourcing}>Vendor Sourcing</StyledHeaderLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <SiteHeader>
        <WebsiteContainer>
          <Nav>
            <LogoSection>
              <StyledHeaderLink to={"/"} replace>
                <img src="/LandingPage/Logo.png" alt="Evendy Logo" />
              </StyledHeaderLink>
            </LogoSection>
            <NavMenu>
              <StyledLi>
                <Dropdown overlay={servicesSubMenu} trigger={["hover"]}>
                  <StyledHeaderLink
                    to={routeNames.servicesHomePage}
                    className="ant-dropdown-link"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                  >
                    Services <DownOutlined />
                  </StyledHeaderLink>
                </Dropdown>
              </StyledLi>
              {/* <StyledLi>
                <StyledHeaderLink to={routeNames.event}>Event</StyledHeaderLink>
              </StyledLi> */}
              {/* <StyledLi>
              <StyledHeaderLink to={"/blog"}>Blog</StyledHeaderLink>
            </StyledLi> */}
              <StyledLi>
                <StyledHeaderLink to={routeNames.contact}>Contact</StyledHeaderLink>
              </StyledLi>{" "}
              <StyledLi>
                <StyledHeaderLink to={routeNames.about}>About Us</StyledHeaderLink>
              </StyledLi>
            </NavMenu>
            <CollaspeButton onClick={toggleLinks}>
              <Menu02 />
            </CollaspeButton>
            <AuthSection>
              {user ? (
                <AppButton as={Link} type="button" variant="secondaryGray" to={userHome}>
                  <Space>
                    <MailTwoTone /> {user.email}
                  </Space>
                </AppButton>
              ) : (
                <>
                  <LoginLink to={routeNames.authLogin} state={{ backgroundLocation: location }}>
                    Log in
                  </LoginLink>
                  <SignupLink to={routeNames.authSignup}>Sign Up</SignupLink>
                </>
              )}
            </AuthSection>
          </Nav>
        </WebsiteContainer>
      </SiteHeader>
      <HeaderPlaceholder />
      <Drawer
        open={showLinks}
        onClose={() => setShowLinks(false)}
        title={
          <StyledHeaderLink to={"/"} replace>
            <img src="/LandingPage/Logo.png" alt="Evendy Logo" />
          </StyledHeaderLink>
        }
      >
        <Nav>
          <MobileNavMenu>
            <StyledLi>
              <StyledHeaderLink to={routeNames.homepage}>Home</StyledHeaderLink>
            </StyledLi>
            <StyledLi>
              <Dropdown overlay={servicesSubMenu} trigger={["hover"]}>
                <StyledHeaderLink
                  to={routeNames.servicesHomePage}
                  className="ant-dropdown-link"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}
                >
                  Services <DownOutlined />
                </StyledHeaderLink>
              </Dropdown>
            </StyledLi>
            {/* <StyledLi>
              <StyledHeaderLink to={"/blog"}>Blog</StyledHeaderLink>
            </StyledLi> */}
            <StyledLi>
              <StyledHeaderLink to={routeNames.contact}>Contact</StyledHeaderLink>
            </StyledLi>{" "}
            <StyledLi>
              <StyledHeaderLink to={routeNames.about}>About Us</StyledHeaderLink>
            </StyledLi>
            <MobileAuthSection>
              <LoginLink to={routeNames.authLogin} state={{ backgroundLocation: location }}>
                Log in
              </LoginLink>
              <SignupLink to={routeNames.authSignup}>Sign Up</SignupLink>
            </MobileAuthSection>
          </MobileNavMenu>
        </Nav>
      </Drawer>
    </>
  );
};
export default Header;

import React from "react";
import styled from "styled-components";
import { WebsiteContainer, AppBadge } from "ui/components/base";
import { routeNames } from "lib/routes";
import { StyledButton } from "./ServicesHomePage";
import {
  Icon,
  MainFrame,
  SubFrame,
  SubFrameHeader,
  SubFrameIcon,
  SubFrameText,
  LearnMore,
  SubFrameParagraph,
} from "./eventTicketing";
import { devices } from "ui/theme";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px;
  max-width: 100%;
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 2.5rem;
  color: rgba(18, 18, 18, 1);
  margin: 0 auto;
`;
const HeaderParagraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: rgba(47, 47, 47, 1);
  line-height: 1.5rem;
`;
const FirstContent = styled.div`
  padding: 0px 32px;
`;
const ContentText = styled.p`
  max-width: auto;
  max-height: 100%;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(47, 47, 47, 1);
  line-height: 1.5rem;
`;
const SecondContent = styled.div`
  padding: 0px 32px;
`;
const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${devices.mobileL} {
  }
  @media (min-width: 790px) {
    flex-direction: row;
    justify-content: center;
    padding: 0 16px;
  }
`;

const VendorSourcing = () => {
  const servicesLink = [
    {
      title: "Event Financing",
      description: "Find sources for Financing your event",
      icon: "/LandingPage/HeroIcon_1.png",
      link: routeNames.financialServices,
    },
    {
      title: "Ticketing",
      description: "Manage your event ticketing and registration with ease",
      icon: "/servicesImages/EventTicketingIcon.png",
      link: routeNames.ticketingPage,
    },
    {
      title: "Analytics",
      description: "Collect insights from your events and use them to make data-driven decisions",
      icon: "/LandingPage/HeroIcon_3.png",
      link: routeNames.analysisPage,
    },
    {
      title: "Event Marketing",
      description: "Manage your event marketing processmore efficiently with our tools",
      icon: "/LandingPage/HeroIcon_4.png",
      link: routeNames.eventMarketing,
    },
    {
      title: "Event Managemant",
      description: "Manage your team, stakeholders, and vendors,as well as multiple events",
      icon: "/LandingPage/HeroIcon_5.png",
      link: routeNames.eventManagement,
    },
  ];
  return (
    <>
      <WebsiteContainer>
        <Header>
          <AppBadge group="Services"></AppBadge>
          <HeaderText>Vendor Sourcing</HeaderText>
          <HeaderParagraph>Finance and manage your event with ease</HeaderParagraph>
          <StyledButton>Get Started</StyledButton>
        </Header>
        <ContentBody>
          <FirstContent>
            <Icon src="/servicesImages/find_vendor_icon.png" alt="find vendors icon" />
            <HeaderText>Find Vendors</HeaderText>
            <ContentText>
              No more wasting hours vetting potential vendors one-by-one. We&apos;ve done the hard work for you,
              hand-picking only highly-rated vendors that continually wow their clients. Simply filter by your location,
              services needed, budget and other criteria to find your perfect matches.
            </ContentText>
          </FirstContent>
          <SecondContent>
            <Icon src="/servicesImages/manage_vendor_icon.png" alt="managing vendors icon" />
            <HeaderText>Managing Vendors</HeaderText>
            <ContentText>
              Once you&apos;ve secured your dream vendor team, seamlessly share event details, files, timelines and more
              through our centralized platform. No more endless email chains or missed communications. Assign tasks,
              give feedback, and keep everyone aligned every step of the way.
            </ContentText>
          </SecondContent>
        </ContentBody>

        <MainFrame>
          {servicesLink.map((content, index) => (
            <SubFrame key={index}>
              <SubFrameIcon>
                <Icon src={content.icon} />
              </SubFrameIcon>
              <SubFrameHeader>
                <SubFrameText>{content.title}</SubFrameText>
              </SubFrameHeader>
              <SubFrameParagraph>{content.description}</SubFrameParagraph>
              <LearnMore to={content.link}>Learn more</LearnMore>
            </SubFrame>
          ))}
        </MainFrame>
      </WebsiteContainer>
    </>
  );
};

export default VendorSourcing;

import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Form, Input, Select, Button, message, notification } from "antd";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { useCreateEvent, useGetUser, useGetUsers } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { routeNames } from "lib/routes";

const { TextArea } = Input;
const { Option } = Select;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  flex-direction: row;
  gap: 30px;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  padding: 12px 40px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

type EventDetailsSchema = {
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
};

const initialEventValues = {
  title: "",
  banner: "https://placehold.co/600x400?text=No+Image",
  organizerEmail: "",
  category: "",
  about: "",
};

const EventDetail = () => {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { data: user } = useGetUser();
  const { mutate: createEvent, isError, error: createEventError } = useCreateEvent();

  const organizerName = useMemo(() => {
    if (user) {
      return `${capitalizeFirstLetter(user.firstName)} ${capitalizeFirstLetter(user.lastName)}`;
    }
    return "";
  }, [user]);

  const organizerEmail = useMemo(() => {
    const session = getSession();
    if (user) {
      return user.email;
    }
    return session?.email;
  }, [user]);

  const handleFinish = async (values: EventDetailsSchema) => {
    setIsSubmitting(true);

    if (!organizerEmail) {
      notification.error({ message: "unable to find organizer details, kindly try agiain" });
      return;
    }

    const payload = {
      title: values.title.toLowerCase().trim(),
      banner: values?.banner || initialEventValues.banner,
      organizerEmail,
      category: values.category.toLowerCase(),
      about: values.about.toLowerCase(),
    };

    createEvent(payload, {
      onSuccess: () => {
        message.success("Event created successfully");
        // Reset form fields
        form.resetFields();
        navigate(routeNames.organizerEvents);
      },
      onError: (error: unknown) => {
        if (axios.isAxiosError(error)) {
          console.error("Error creating event:", error.response?.data || error.message);
          message.error(error.response?.data?.message || "Failed to create event.");
        } else {
          console.error("Error creating event:", (error as Error).message);
          message.error("An unexpected error occurred.");
        }
        setIsSubmitting(false);
      },
    });
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <p>Event details</p>
      <Form
        layout="vertical"
        style={{ maxWidth: "600px", margin: "0 auto" }}
        form={form}
        onFinish={handleFinish}
        requiredMark={false}
        initialValues={initialEventValues}
      >
        <Form.Item
          label="Event Title"
          name="title"
          rules={[{ required: true, message: "Please enter the event title" }]}
        >
          <Input placeholder="Enter the event title" style={{ paddingLeft: "14px" }} />
        </Form.Item>

        <Form.Item label="Organizer">
          <Input style={{ paddingLeft: "14px" }} value={organizerName} readOnly />
        </Form.Item>

        <Form.Item
          label="Event Category"
          name="category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select placeholder="Select category">
            <Option value="ArtExhibitions">Art Exhibitions</Option>
            <Option value="CharityFundraisers">Charity Fundraisers</Option>
            <Option value="ComedyShows">Comedy Shows</Option>
            <Option value="Conferences">Conferences</Option>
            <Option value="Educational">Educational Workshops</Option>
            <Option value="Fashion">Fashion Shows</Option>
          </Select>
        </Form.Item>

        <Form.Item label="About Event" name="about" rules={[{ required: true, message: "Please describe the event" }]}>
          <TextArea
            rows={4}
            placeholder="Describe the event for attendees to know what's up"
            style={{ paddingLeft: "14px" }}
          />
        </Form.Item>

        <Form.Item>
          <ButtonContainer>
            <Link to={routeNames.organizerHome}>
              <GhostButton>Cancel</GhostButton>
            </Link>
            <ButtonWrapper>
              <SubmitButton form={form} loading={isSubmitting}>
                Save and Continue
              </SubmitButton>
            </ButtonWrapper>
          </ButtonContainer>
        </Form.Item>
      </Form>
    </>
  );
};

export default EventDetail;

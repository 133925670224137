import React from "react";
import { WebsiteContainer } from "ui/components/base";
import ContactForm from "./contactForm";
import styled from "styled-components";

const ContactImg = styled.img`
  height: auto;
`;
const ContactSection = styled.div`
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: stretch;
    padding: 10px;
  }
`;
const Contact = () => {
  return (
    <>
      <WebsiteContainer>
        <ContactSection>
          <ContactImg src="/Eventimages/contact_us.png" />
          <ContactForm />
        </ContactSection>
      </WebsiteContainer>
    </>
  );
};
export default Contact;

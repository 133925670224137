import styled from "styled-components";
import { devices } from "ui/theme";
import { screenSizes } from "ui/theme";
import { Link } from "react-router-dom";

const FooterContainer = styled.footer`
  background-color: rgba(12, 17, 29, 1);
  padding: 40px 16px;
  @media (min-width: ${screenSizes.laptop}) {
    padding: 20px;
  }
`;
const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 64px;
  justify-content: space-between;
`;
const SectionA = styled.div`
  width: 100%;
  @media (min-width: ${screenSizes.laptop}) {
    padding: 20px;
  }
`;
const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Logo = styled.img`
  width: 100px;
  height: auto;
`;

const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  gap: 30px;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-family: "BR Firma";
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: rgba(208, 213, 221, 1);
`;
const Social = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${screenSizes.laptop}) {
    justify-content: space-between;
    flex-direction: row;
  }
`;
const SocialIcons = styled.div`
  color: rgba(208, 213, 221, 1);
  display: flex;
  align-items: center;
  gap: 24px;
`;
const Icon = styled.img`
  width: 24px;
  height: auto;
`;
const IconLink = styled.a``;
const SectionB = styled.div`
  display: flex;
  justify-content: space-between;
`;

export {
  Icon,
  FooterContainer,
  Section,
  SectionA,
  Logo,
  FooterLink,
  Paragraph,
  Social,
  SocialIcons,
  SectionB,
  IconLink,
  FooterColumn,
};

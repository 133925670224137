import React from "react";
import CounterComponent from "./Benefits/Benefits";
import FAQSection from "./faq/faq";
import Hero from "./hero/Hero";
import Section from "./sectionImag/ImgSect";
import { LandingPageServices } from "./serviceSection/LandingPageServices";
import Testimonial from "./Testimonial/Testimonials";

const LandingPage = () => {
  return (
    <>
      <Hero />
      <LandingPageServices />
      <CounterComponent />
      <Section />
      <FAQSection />
      <Testimonial />
    </>
  );
};

export default LandingPage;

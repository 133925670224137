import React from "react";
import { subframeContent } from "./data";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import { Col, Row, Typography } from "antd";
import {
  LearnMore,
  LeftContent,
  MainFrame,
  RightContent,
  StyledButton,
  SubFrameHeader,
  SubFrameResponsive,
  SubFrameText,
  SubHeaderText,
  SubHeroContainer,
  SubParagraphText,
  SubFrameDetail,
} from "./LandingPageService.style";
import { BlueText } from "../hero/Hero.style";
import { AppBadge, WebsiteContainer } from "ui/components/base";

const SubContainer = styled.div`
  max-width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SubFrameIcon = styled.div``;

const Icon = styled.div``;

export const LandingPageServices = () => {
  return (
    <WebsiteContainer>
      <SubHeroContainer>
        <SubContainer>
          <LeftContent>
            <AppBadge group="Services">Create better event experience</AppBadge>
            <SubHeaderText>
              Acheive <BlueText>more</BlueText>, stress less
            </SubHeaderText>
            <SubParagraphText>Improve the Experience of your Event </SubParagraphText>
          </LeftContent>
          <RightContent>
            <Link to={routeNames.authSignup}>
              <StyledButton>Create an event</StyledButton>
            </Link>
          </RightContent>
        </SubContainer>

        <MainFrame>
          <Row
            gutter={[
              { xs: 0, sm: 24, lg: 48 },
              { xs: 16, sm: 24, lg: 48 },
            ]}
          >
            {subframeContent.map((content, index) => (
              <Col key={index} xs={24} sm={12} lg={8}>
                <SubFrameResponsive>
                  <SubFrameIcon>
                    <Icon>
                      {" "}
                      <img alt="" src={content.icon} />{" "}
                    </Icon>
                  </SubFrameIcon>
                  <SubFrameHeader>
                    <SubFrameText>{content.title}</SubFrameText>
                  </SubFrameHeader>
                  <SubFrameDetail>{content.description}</SubFrameDetail>
                  <LearnMore href={content.link}>Learn more</LearnMore>
                </SubFrameResponsive>
              </Col>
            ))}
          </Row>
        </MainFrame>
      </SubHeroContainer>
    </WebsiteContainer>
  );
};

import React from "react";
import styled from "styled-components";
import { StyledButton } from "./ServicesHomePage";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import {
  Icon,
  MainFrame,
  SubFrame,
  SubFrameHeader,
  SubFrameIcon,
  SubFrameText,
  LearnMore,
  SubFrameParagraph,
} from "./eventTicketing";
import { WebsiteContainer, AppBadge } from "ui/components/base";
import { devices } from "ui/theme";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px;
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 40px;
  color: rgba(18, 18, 18, 1);
`;
const HeaderParagraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: rgba(47, 47, 47, 1);
  line-height: 24px;
  margin-top: -20px;
`;
const LeftContent = styled.div`
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 96px 64px 96px 0px;
  gap: 4rem;
  @media screen and (max-width: 768px) {
    padding: 48px 32px;
  }
`;
const ContentText = styled.p`
  max-width: 512px;
  height: auto;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(47, 47, 47, 1);
  line-height: 1.5rem;
  text-align: left;
  margin-top: -40px;
`;
const RightContent = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  display: none;
  @media ${devices.mobileL} {
    display: block;
    border-radius: 0px;
    margin-top: 32px;
  }
`;
const ContentHeader = styled.p`
  display: flex;
  font-weight: 500;
  font-size: 2.5rem;
  color: rgba(18, 18, 18, 1);
  max-width: 512px;
  height: auto;
  margin-top: -20px;
  margin-bottom: 0;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const MobileContent = styled.img`
  display: block;
  @media ${devices.mobileL} {
    display: none;
  }
`;

const EventFinancingPage = () => {
  const servicesLink = [
    {
      title: "Ticketing",
      description: "Manage your event ticketing and registration with ease",
      icon: "/servicesImages/EventTicketingIcon.png",
      link: routeNames.ticketingPage,
    },
    {
      title: "Analytics",
      description: "Collect insights from your events and use them to make data-driven decisions",
      icon: "/LandingPage/HeroIcon_3.png",
      link: routeNames.analysisPage,
    },
    {
      title: "Event Marketing",
      description: "Manage your event marketing processmore efficiently with our tools",
      icon: "/LandingPage/HeroIcon_4.png",
      link: routeNames.eventMarketing,
    },
    {
      title: "Event Managemant",
      description: "Manage your team, stakeholders, and vendors,as well as multiple events",
      icon: "/LandingPage/HeroIcon_5.png",
      link: routeNames.eventManagement,
    },
    {
      title: "Vendor Sourcing",
      description: "FInd the vendors you need to make your event successful",
      icon: "/LandingPage/HeroIcon_6.png",
      link: routeNames.eventVendorSourcing,
    },
  ];
  return (
    <>
      <WebsiteContainer>
        <Header>
          <AppBadge group="Services"></AppBadge>
          <HeaderText>Event Financing</HeaderText>
          <HeaderParagraph>Finance and manage your event with ease</HeaderParagraph>
          <StyledButton>Get started</StyledButton>
        </Header>

        <Content>
          <LeftContent>
            <Icon src="/LandingPage/HeroIcon_1.png" alt="event financing icon" />
            <ContentHeader>Event Financing</ContentHeader>
            <ContentText>
              No more wondering if you&apos;re overshooting your budget. Evendy&apos;s finance tools let you manage your
              budget and track expenses throughout the entire planning and executing phase. Need working capital? We
              offer event financing options for your events. We can provide you with the needed finances ( loan) to
              organize your event. Terms and condition applies.
            </ContentText>
          </LeftContent>
          <RightContent src="/servicesImages/EventFinancing.png" />
          <MobileContent src="/servicesImages/FinanceMobile.png" />
        </Content>

        <MainFrame>
          {servicesLink.map((content, index) => (
            <SubFrame key={index}>
              <SubFrameIcon>
                <Icon src={content.icon} />
              </SubFrameIcon>
              <SubFrameHeader>
                <SubFrameText>{content.title}</SubFrameText>
              </SubFrameHeader>
              <SubFrameParagraph>{content.description}</SubFrameParagraph>
              <LearnMore to={content.link}>Learn more</LearnMore>
            </SubFrame>
          ))}
        </MainFrame>
      </WebsiteContainer>
    </>
  );
};
export default EventFinancingPage;

import { sessionStorageKeys } from "lib/constants";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { AppModal } from "ui/components/base";
import { routeNames } from "lib/routes";
import authBG from "./images/modal/EvendyHome.png";
import { HEADER_HEIGHT } from "app/pages/website/header/Header.style";
import Header from "app/pages/website/header/Header";

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
`;

const StyledRoot = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${authBG});
  background-repeat: no-repeat;
  background-size: 100%;
`;

const Auth: React.FC = () => {
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [userType, setUserType] = useState<"Organizer" | "Vendor" | null>(null);

  const navigate = useNavigate();

  const handleRequestModalClose = () => {
    // navigate(-1);
    navigate(routeNames.homepage);
  };

  // Use useEffect to open the modal when the component mounts
  useEffect(() => {
    setRequestModalOpen(true);
  }, []);

  return (
    <>
      <Header />
      <StyledRoot>
        <AppModal open={true} onCancel={handleRequestModalClose} closable={false} header={<span></span>} size="md">
          <ModalBodyContainer>
            <Outlet context={{ userType, setUserType }} />
          </ModalBodyContainer>
        </AppModal>
      </StyledRoot>
    </>
  );
};

export default Auth;

import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { EvendyApi } from "lib/utils/api-utils";

interface VendorBankInfo {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankCode: number;
  vendorEmail: string;
}

// Payload type for the mutation
type EditVendorBankPayload = VendorBankInfo;

// Hook to edit vendor bank information
export function useEditVendorBank(): UseMutationResult<void, Error, EditVendorBankPayload> {
  return useMutation({
    mutationKey: ["vendor-edit-bank"], // Direct string used as the mutation key
    mutationFn: async (payload: EditVendorBankPayload): Promise<void> => {
      const { bankName, bankAccountName, bankAccountNumber, bankCode, vendorEmail } = payload;
      await EvendyApi.post<void>(`/vendor-settings/bank-details`, {
        bankName,
        bankAccountName,
        bankAccountNumber,
        bankCode,
        vendorEmail,
      });
    },
    retry: false,
  });
}

import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

interface VendorPackage {
  id: number;
  title: string;
  description: string;
  currency: string;
  price: number;
  revenue: number;
  upfrontPayment: number;
  vendorEmail: string;
}

type VendorPackagesResult = UseQueryResult<VendorPackage[], Error>;

type GetVendorPackagesByEmailPayload = {
  email: string;
};

export function useGetVendorPackagesByEmail(payload: GetVendorPackagesByEmailPayload): VendorPackagesResult {
  const { email } = payload;

  return useQuery({
    queryKey: [tqQueryKeys.packages.getVendorPackagesByEmail, email],
    queryFn: async (): Promise<VendorPackage[]> => {
      const res = await EvendyApi.get<VendorPackage[]>(`vendor-packages/findByEmail/${email}`);
      return res.data;
    },
    enabled: !!email,
    retry: false,
  });
}

import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, Select } from "antd";
import styled from "styled-components";
import { ReactComponent as Search } from "images/vendor/search.svg";
import SubmitButton from "./Button";
import { getSession } from "lib/utils/utils";
import { useEditVendorById } from "lib/hooks";
import { useGetUsers, useGetVendor } from "lib/hooks";
import { useParams, useSearchParams } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

const Container = styled.div`
  width: 100%;
`;
const MessageBox = styled(TextArea)`
  min-height: 70px;
`;

const FormBox = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const SearchIcon = styled(Search)`
  width: 16px;
  height: 16px;
  color: #9c9c9c;
`;

const Label = styled.div``;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 3rem;
`;

const EnterLocationLink = styled.a`
  font-size: 0.8rem;
  color: #1890ff;
  justify-self: end;
`;

const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const SubmitContainer = styled.div`
  display: flex;
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const EmailInput = styled(Input)`
  background-color: #d9d9d9;
  color: #9c9c9c;
`;

const AccountDetails: React.FC = () => {
  const [form] = Form.useForm();
  const [userId, setUserId] = useState<number | null>(null);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { data: usersData, error: usersError } = useGetUsers();

  // //param for the routing
  // const [searchParams] = useSearchParams();
  // const vendorId = searchParams.get("vendorId")

  const { mutate: editVendor, isError, error, isSuccess, data: responseData } = useEditVendorById();

  useEffect(() => {
    const session = getSession();
    if (session) {
      setUserId(session.userId);
    }
  }, []);

  // Load form values from sessionStorage
  useEffect(() => {
    if (userId && usersData) {
      const user = usersData.find(user => user.id === userId);
      if (user) {
        form.setFieldsValue({
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.email,
        });
      }
    }
    if (usersError) {
      console.error("Error fetching users data:", usersError);
    }
  }, [userId, usersData, usersError, form]);

  useEffect(() => {
    if (isSuccess && responseData) {
      form.setFieldsValue({
        businessName: responseData.businessName,
        businessCategory: responseData.businessCategoryName,
        businessDescription: responseData.description,
        phoneNumber: responseData.phone,
        businessLocation: responseData.address,
      });
    }
  }, [isSuccess, responseData, form]);

  const handleFinish = useCallback(
    (values: any) => {
      if (loading) return;

      setLoading(true);
      if (userId === null) {
        console.error("User ID is null, cannot update vendor profile.");
        setLoading(false);
        return;
      }
      const payload = {
        id: userId,
        data: {
          businessName: values.businessName,
          businessCategoryName: values.businessCategory,
          address: values.businessLocation,
          organizerEmail: email,
          description: values.businessDescription || "string",
          country: "string",
          state: "string",
          city: "string",
        },
      };

      editVendor(payload, {
        onSuccess: () => {
          console.log("Vendor profile successfully updated");
        },
        onError: error => {
          console.error("Error updating vendor profile:", error);
          setLoading(false);
        },
      });
    },
    [editVendor, form, loading, userId, email], // Add userId and email to dependencies
  );

  return (
    <Container>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        initialValues={{ remember: true }}
        requiredMark={false}
      >
        <FormBox>
          <Form.Item
            label="Business Name"
            name="businessName"
            rules={[{ required: true, message: "Please input your business name!" }]}
          >
            <Input placeholder="e.g John's Bakery" style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
        </FormBox>
        <FormBox>
          <Form.Item
            label="Business Category"
            name="businessCategory"
            rules={[{ required: true, message: "Please select your business category!" }]}
          >
            <Select placeholder="Select category" style={{ fontSize: "0.8rem", color: "#9C9C9C", width: "100%" }}>
              <Option value="catering">Catering</Option>
              <Option value="decoration">Decoration</Option>
              <Option value="drinks">Drinks</Option>
              <Option value="entertainment">Entertainment</Option>
              <Option value="furniture">Furniture rentals</Option>
              <Option value="lodgings">Lodgings</Option>
            </Select>
          </Form.Item>
        </FormBox>
        <FormBox>
          <Form.Item
            label="Business Description"
            name="businessDescription"
            rules={[{ required: true, message: "Please input your business description!" }]}
          >
            <MessageBox
              placeholder="Craft an impressive description. Showcase your strengths and achievements"
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }}
            />
          </Form.Item>
        </FormBox>
        <FormBox>
          <Form.Item
            label={
              <LabelContainer>
                <Label>Business Location</Label>

                <EnterLocationLink href="#">Enter Location Manually</EnterLocationLink>
              </LabelContainer>
            }
            name="businessLocation"
            rules={[{ required: true, message: "Please input your business location!" }]}
          >
            <Input
              placeholder="Search for location or address"
              prefix={<SearchIcon />}
              style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }}
            />
          </Form.Item>
        </FormBox>
        <RowContainer>
          <FormBox style={{ flex: 1 }}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "Please input your first name!" }]}
            >
              <Input placeholder="First Name" style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
            </Form.Item>
          </FormBox>
          <FormBox style={{ flex: 1 }}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Please input your last name!" }]}
            >
              <Input placeholder="Last Name" style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
            </Form.Item>
          </FormBox>
        </RowContainer>
        <FormBox>
          <Form.Item
            label="Email Address"
            name="emailAddress"
            rules={[{ required: true, message: "Please input your email address!" }]}
          >
            <EmailInput readOnly style={{ width: "100%", fontSize: "0.8rem" }} />
          </Form.Item>
        </FormBox>
        <FormBox>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[{ required: true, message: "Please input your phone number!" }]}
          >
            <Input style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
        </FormBox>
        <SubmitContainer>
          <SubmitButton form={form}>Save changes</SubmitButton>
        </SubmitContainer>
        {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>}
      </Form>
    </Container>
  );
};

export default AccountDetails;

import React, { useRef } from "react";
import { Carousel } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { AppBadge, WebsiteContainer } from "ui/components/base";
import {
  BlueText,
  Compartment,
  CompartmentA,
  CompartmentB,
  Header,
  Icon,
  IconRow,
  MainText,
  MainWrapper,
  PaginationDot,
  PaginationWrapper,
  Paragraph,
  Quote,
  SubText,
  TestimonialContentWrapper,
  TestimonialImage,
  TestimonialWrapper,
} from "./Testimonials.style";

const Testimonial: React.FC = () => {
  const carouselRef = useRef<CarouselRef>(null);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    carouselRef.current?.goTo(index);
  };
  const testimonial = [
    {
      quote: "The service was good and vendor easy to  work with. Would recommend them",
      author: "-Adedamola Oni",
      role: "CTO, Evendy",
    },
    {
      quote: "Their services was really amazing and Professional. We will always recommend them",
      author: "-Samirah Bello",
      role: "Thrive Agric",
    },
    {
      quote:
        "We've had consistent exceptional experience with their vendors and services which has enhanced our events. Their vendors are reliable, professional, and deliver high-quality services.",
      author: "-Titi",
      role: "Alt School",
    },
  ];
  return (
    <WebsiteContainer>
      <MainWrapper>
        <Compartment>
          <AppBadge group="Testimonials">
            <p>what great people have to say</p>
          </AppBadge>
          <Header>
            Don&apos;t Just take <BlueText>our</BlueText> word for it
          </Header>
          <Paragraph>Hear from some of our amazing customers who are automating their finances</Paragraph>
        </Compartment>

        <TestimonialWrapper>
          <CompartmentA>
            <Carousel effect="fade" autoplay afterChange={setActiveIndex} dots={false} ref={carouselRef}>
              {testimonial.map((testimonial, index) => (
                <TestimonialContent
                  key={index}
                  quote={testimonial.quote}
                  author={testimonial.author}
                  role={testimonial.role}
                />
              ))}
            </Carousel>

            <PaginationWrapper>
              {testimonial.map((_, index) => (
                <PaginationDot
                  key={index}
                  className={index === activeIndex ? "active" : ""}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </PaginationWrapper>
          </CompartmentA>

          <CompartmentB>
            <TestimonialImage src="/LandingPage/testimonialimg.svg" alt="Testimonial image" />
          </CompartmentB>
        </TestimonialWrapper>
      </MainWrapper>
    </WebsiteContainer>
  );
};

const TestimonialContent: React.FC<{ quote: string; author: string; role: string }> = ({ quote, author, role }) => {
  return (
    <TestimonialContentWrapper>
      <IconRow>
        <Icon />
        <Icon />
        <Icon />
        <Icon />
        <Icon />
      </IconRow>
      <Quote>{quote}</Quote>
      <MainText>{author}</MainText>
      <SubText>{role}</SubText>
    </TestimonialContentWrapper>
  );
};

export default Testimonial;

import React, { useState } from "react";
import { Form, Button, Input, Select, message } from "antd";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneNumberValidation from "./PhoneNumberValidation";

const { TextArea } = Input;

const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_FORM_EMAIL;

const FormContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 96px 0px;
  gap: 64px;
  @media (max-width: 700px) {
    padding: 0;
  }
`;
const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 512px;
  height: auto;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
const HeaderText = styled.div`
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3rem;
  color: rgba(16, 24, 40, 1);
`;
const Paragraph = styled.div`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  color: rgba(71, 84, 103, 1);
`;
const FormGroup = styled.div`
  margin-top: 1.8rem;
  display: flex;
  flex-direction: column;
`;
const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
`;
const FormBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;
const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 1.2rem;
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputBox = styled(Input)`
  width: 100%;
  height: 44px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  @media (max-width: 760px) {
    padding: 0;
  }
`;
const EmailBox = styled(Input)`
  width: 100%;
  height: 44px;
  padding: 10px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`;
const MessageBox = styled(TextArea)`
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`;
const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  p {
    font-weight: 1rem;
    font-size: 1rem;
  }
`;

const ContactForm: React.FC = () => {
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumer] = useState<string>("");
  const [loading, setLoading] = useState(false);
  // google recaptcha
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA;

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  if (!recaptchaKey) {
    throw new Error("REACT_APP_GOOGLE_RECAPTCH is not defined");
  }

  const handleRecaptchaVerify = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const onFinish = async (values: any) => {
    if (!recaptchaToken) {
      message.error("Please complete the reCAPTCHA verification.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`https://formsubmit.co/${CONTACT_EMAIL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...values, recaptchaToken }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`);
      }
      message.success("Form submitted successfully!");
      window.location.reload();
    } catch (error) {
      if (error instanceof TypeError) {
        message.error("Network error occurred. Please check your internet connection.");
      } else {
        message.error("Failed to submit form, please try again.");
      }
      console.error("Failed to submit form:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form onFinish={onFinish}>
      <FormContainer>
        <FormSection>
          <HeaderText>Let’s level up your brand, together</HeaderText>
          <Paragraph>
            You can reach us anytime via <a href="mailto:support@evendy.co">support@evendy.co</a>
          </Paragraph>
          <FormGroup>
            <FormBoxRow>
              <LabelWrapper>
                <Label>First name</Label>
                <Form.Item name="firstName" rules={[{ required: true, message: "Please input your First name!" }]}>
                  <InputBox placeholder="First name" />
                </Form.Item>
              </LabelWrapper>
              <LabelWrapper>
                <Label>Last name</Label>
                <Form.Item name="lastName" rules={[{ required: true, message: "Please input your Last name!" }]}>
                  <InputBox placeholder="Last name" />
                </Form.Item>
              </LabelWrapper>
            </FormBoxRow>
            <FormBox>
              <Label>Email</Label>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <EmailBox placeholder="you@company.com" />
              </Form.Item>
            </FormBox>
            <FormBox>
              <Label>Phone Number</Label>
              <Form.Item name="phoneNumber" rules={[{ required: true, message: "Please input your Phone number!" }]}>
                <PhoneNumberValidation />
              </Form.Item>
            </FormBox>
            <FormBox>
              <Label>Message</Label>
              <Form.Item name="message" rules={[{ required: true, message: "Please input your Message!" }]}>
                <MessageBox placeholder="Leave us a message" />
              </Form.Item>
            </FormBox>
          </FormGroup>
          <Form.Item>
            <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaVerify} />
          </Form.Item>
          <StyledButton htmlType="submit" loading={loading}>
            Submit
          </StyledButton>
        </FormSection>
      </FormContainer>
    </Form>
  );
};

export default ContactForm;

import React, { useState } from "react";
import {
  FAQHeader,
  FAQSubHeader,
  FAQContainer,
  ItemContainer,
  TextHeader,
  CircleIcon,
  BottomContainer,
  HeaderText,
  SubHeaderText,
  StyledButton,
  TextParagraph,
} from "./faq.style";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import { BlueText } from "../hero/Hero.style";
import { AppBadge, WebsiteContainer } from "ui/components/base";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibilty = () => {
    setIsVisible(!isVisible);
  };

  return (
    <ItemContainer onClick={toggleVisibilty}>
      <TextHeader>
        <div>{question}</div>
        <CircleIcon>{isVisible ? "-" : "+"}</CircleIcon>
      </TextHeader>
      {isVisible && <TextParagraph>{answer}</TextParagraph>}
    </ItemContainer>
  );
};

const FAQSection: React.FC = () => {
  return (
    <FAQContainer>
      <AppBadge group="FAQs">
        {" "}
        <p>A few frequent questions</p>
      </AppBadge>
      <WebsiteContainer>
        <FAQHeader level={3}>
          <BlueText>Frequently</BlueText> asked questions.
        </FAQHeader>
        <FAQSubHeader>Everything you need to know about the Evendy</FAQSubHeader>
        <FAQItem
          question="What is Evendy?"
          answer="Evendy is a comprehensive event management platform designed to simplify and enhance the event handling process. Our platform offers a large range of tools and features to assist you in organizing, managing, and promoting your events.
          Get verified vendors for your events and manage your event processes from one place."
        />
        <FAQItem
          question="How can i get started with Evendy?"
          answer="Getting started with Evendy is easy! Simply visit our website at www.evendy.co and sign up for an account. Once you're registered, you can explore our features, create events, start managing your event planning tasks and much more."
        />
        <FAQItem
          question="What types of events can I organize using Evendy?"
          answer="Evendy is versatile and can be used for a wide range of events, including conferences, seminars, weddings, parties, fundraisers, trade shows, and more. Our platform is customizable to suit various event types and sizes."
        />
        <FAQItem
          question="What features does Evendy offer?"
          answer="Evendy offers a suite of features, including registrations and ticketing, vendor sourcing and management, event financing, marketing solutions, analytics and insights, collaboration tools, and a mobile-friendly experience. These tools are designed to streamline your event planning process and enhance event success."
        />
        <FAQItem
          question="Can I customize the registration process for my event?"
          answer="Absolutely! Evendy allows you to create customized registration forms and set up different ticket types. This ensures that your attendees have a seamless registration and ticketing experience."
        />
        <FAQItem
          question="How can Evendy help me with vendor management?"
          answer="Our platform facilitates vendor sourcing and management by connecting you with trusted vendors with different services. You can connect with vendors, negotiate contracts, and ensure that all aspects of your event align with your vision."
        />
        <FAQItem
          question="Can I communicate with vendors directly on your platform?"
          answer="No, all communication is done on the evendy platform and a representative is available to assist you through the process."
        />
        <FAQItem
          question="Is Evendy suitable for both small and large-scale events?"
          answer="Yes, Evendy is designed to cater to events of all sizes. Whether you're planning an intimate gathering or a large-scale conference, our platform offers tools that can be adapted to meet your event's unique needs"
        />
        <FAQItem
          question="How can I promote my event using Evendy?"
          answer="Evendy's marketing feature provides you with tools and partners to help you amplify your event's reach and engage with your target audience effectively."
        />
        <FAQItem
          question="Is my data secure on the Evendy platform?"
          answer="Yes, we take data security seriously. Our platform employs advanced security measures to protect your information and ensure that your data is kept confidential and secure."
        />
        <FAQItem
          question="How can I get support if I encounter issues?"
          answer="If you have any questions, concerns, or issues, our dedicated customer support team is here to assist you. You can reach out to us via email at support@evendy.co or by calling our customer support hotline at +2349039504875."
        />

        <FAQItem
          question=" Can I integrate Evendy with other event tools I use?"
          answer=" We are continuously working to enhance the platform's capabilities, including potential integrations. Feel free to reach out to us with specific integration inquiries at support@evendy.co"
        />
        <FAQItem
          question="Can I create a discount for my ticket?"
          answer="Yes. When creating an event, you can create coupons and set how users can use them."
        />
        <FAQItem
          question="What sets Evendy different from other event planning and vendor sourcing platforms?"
          answer="Yes, you can try us for free for 30 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
        />
      </WebsiteContainer>
      <BottomContainer>
        <img alt="" src="/LandingPage/FaqAvatar.png" />
        <HeaderText>Still Have Question?</HeaderText>
        <SubHeaderText>
          Can&apos;t find the answer you&apos;re looking for? Please chat to our friendly team.
        </SubHeaderText>
        <Link to={routeNames.contact}>
          <StyledButton>Get in touch</StyledButton>
        </Link>
      </BottomContainer>
    </FAQContainer>
  );
};
export default FAQSection;

import React from "react";
import styled from "styled-components";
import { devices } from "ui/theme";

const SectionWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
const ImageContainer = styled.div`
  width: 100%;
`;
const BigImage = styled.img`
  display: none;
  width: 100%;
  height: auto;
  @media ${devices.mobileL} {
    display: block;
  }
`;
const MobileContent = styled.div`
  display: block;

  @media ${devices.mobileL} {
    display: none;
    align-items: center;
    flex-direction: column;
  }
`;
const MobileContentImages = styled.img`
  width: 365px;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
const Section = () => {
  return (
    <SectionWrapper>
      <ImageContainer>
        <BigImage src="/LandingPage/main_img.png" />
      </ImageContainer>

      <MobileContent>
        <MobileContentImages src="/LandingPage/mobile_image_1.png" alt="mobile view image" />
        <MobileContentImages src="/LandingPage/mobile_image_2.png" alt="mobile view image" />
        <MobileContentImages src="/LandingPage/mobile_image_3.png" alt="mobile view image" />
      </MobileContent>
    </SectionWrapper>
  );
};

export default Section;

import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT } from "../../header/Header.style";
import { screenSizes } from "ui/theme";
import { Button, Typography } from "antd";

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 38px;
`;

export const HeroContent = styled.div``;

export const BlueText = styled.span`
  color: #1390e6;
`;
export const HeroImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  @media (min-width: ${screenSizes.laptop}) {
    padding: 0 88px;
  }
`;

export const HeroImage = styled.img`
  max-width: 1216px;
  width: 100%;
  height: 100%;

  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
`;

export const SponsorSection = styled.section`
  display: flex;
  padding: 10px;
  flex-direction: column;
  text-align: center;
  flex: 1;
  @media screen and (min-width: ${screenSizes.laptopL}) {
    padding: 20px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 44px;
  align-items: center;
  overflow-x: auto;
  @media screen and (min-width: ${screenSizes.laptopL}) {
    padding: 0;
    flex-direction: row;
  }
`;

export const SponsorLogo = styled.img`
  max-width: 100%;
  width: fit-content;
  height: auto;
`;
const { Title } = Typography;

export const SponsorTitle = styled(Title)`
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5rem;
  color: rgba(47, 47, 47, 1);
`;
export const BGWrapper = styled.div`
  background-image: url("/LandingPage/HeroImage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  /* height: calc(100vh - ${HEADER_HEIGHT}); */
  padding: 7.5rem auto;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 0;
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 4rem;
    text-align: center;
    color: #121212;
  }

  a {
    color: #1390e6;
  }

  @media screen and (min-width: ${screenSizes.laptop}) {
    max-width: 889px;
    min-width: 100px;
  }
`;
export const ParagraphText = styled.div`
  text-align: center;
  margin-top: 1.5rem;

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }

  @media screen and (min-width: ${screenSizes.laptop}) {
    max-width: 889px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 40px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  margin-right: 10px;
  width: 190px;
`;

import React from "react";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import styled from "styled-components";
import { Typography } from "antd";
import { WebsiteContainer } from "ui/components/base";
import {
  AboutUs,
  Section,
  Badge,
  BadgeGroup,
  BadgeGroupImg,
  AboutContent,
  HeaderText,
  Paragraph,
  SponsorSection,
  LogoContainer,
  SponsorLogo,
  SubHeroContainer,
  SubContainer,
  LeftContent,
  RightContent,
  StyledButton,
  SubHeaderText,
  AboutImg,
} from "./about.style";
import ServicesHome from "../services/ServicesHomePage";

const { Title } = Typography;

const SponsorTitle = styled(Title)`
  && {
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    color: rgba(47, 47, 47, 1);
  }
`;

const About = () => {
  return (
    <>
      <AboutUs>
        <Outlet />
        <>
          <Section>
            <Badge>
              <BadgeGroup>
                <BadgeGroupImg src="/badge_group_img.png" />
                <p>About us</p>
              </BadgeGroup>
            </Badge>
            <AboutContent>
              <HeaderText>
                &quot;Our mission is to guarantee that event creators provide unparalleled event experiences.&quot;
              </HeaderText>
              <Paragraph>
                Untitled is a technology company that builds infrastructure for your startup, so you don’t have to.
                Businesses of every size—from new startups to public companies—use our software to manage their
                businesses.
              </Paragraph>
            </AboutContent>
            <SponsorSection>
              <SponsorTitle level={3}>Our sponsors and partners</SponsorTitle>
              <LogoContainer>
                <SponsorLogo src="/Techpoint-logo-500px-2 1.png"></SponsorLogo>
                <SponsorLogo src="/LandingPage/alticon.png"></SponsorLogo>
                <SponsorLogo src="/LandingPage/HeroIcon.png"></SponsorLogo>
                <SponsorLogo src="/image 4.png"></SponsorLogo>
              </LogoContainer>
            </SponsorSection>
          </Section>
        </>
      </AboutUs>
      <WebsiteContainer>
        <SubHeroContainer>
          <SubContainer>
            <LeftContent>
              <SubHeaderText>
                Acheive <span>more</span>, stress less
              </SubHeaderText>
              <Paragraph>Improve the Experience of your Event </Paragraph>
            </LeftContent>
            <Link to={routeNames.authSignup}>
              <StyledButton>Create an event</StyledButton>
            </Link>
          </SubContainer>
        </SubHeroContainer>
        <AboutImg src="/LandingPage/About_img.png" alt="about us image" />
      </WebsiteContainer>
      <ServicesHome />
    </>
  );
};
export default About;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Tabs } from "antd";
import { ReactComponent as Cam } from "images/vendor/Cam.svg";
import { ReactComponent as LocationSvg } from "images/vendor/Location.svg";
import AccountDetails from "./AccountDetails";
import SocialAccounts from "./SocialAccounts";
import Settings from "./Settings";
import { getSession } from "lib/utils/utils";
import { useGetUsers } from "lib/hooks";

const Section = styled.div`
  margin-top: 1rem;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 90%;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const CamContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: -90px;
  margin-left: 85px;
`;

const LeftParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BusinessName = styled.div`
  margin-top: 0;
  margin-bottom: 0;
`;

const LargText = styled.p`
  text-align: left;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 0;
  }
`;

const Circle = styled.div`
  width: 20vw;
  height: 20vw;
  max-width: 128px;
  max-height: 128px;
  border-radius: 50%;
  background-color: #1390e6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  position: relative;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    width: 40vw;
    height: 40vw;
    font-size: 0.9rem;
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  gap: 10px;
`;

const TopRight = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledButton = styled(Button)`
  width: 177px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  color: rgba(47, 47, 47, 1);
  font-weight: 500;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 20px;
  color: rgba(89, 89, 89, 1);
  width: 100%;
  padding: 10px;
`;

const ScrollableTabContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding: 10px;

  /* Hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const OrganizerProfile: React.FC = () => {
  const [userId, setUserId] = useState<number | null>(null);
  const [name, setName] = useState<string>("");

  const [initials, setInitials] = useState<string>("");

  const { data: usersData, error: usersError, refetch } = useGetUsers();
  useEffect(() => {
    const session = getSession();
    if (session) {
      setUserId(session.userId);
    }
  }, []);

  useEffect(() => {
    if (userId && usersData) {
      const user = usersData.find(user => user.id === userId);
      if (user) {
        const fullName = `${capitalizeFirstLetter(user.firstName)} ${capitalizeFirstLetter(user.lastName)}`;
        setName(fullName);
        setInitials(`${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}`);
      }
    }
    if (usersError) {
      console.error("Error fetching users data:", usersError);
    }
  }, [userId, usersData, usersError]);

  const items = [
    {
      key: "1",
      label: "Account Details",
      children: <AccountDetails refetchUsers={refetch} />,
    },
    {
      key: "5",
      label: "Social Accounts",
      children: <SocialAccounts />,
    },
    {
      key: "6",
      label: "Settings",
      children: <Settings />,
    },
  ];

  return (
    <Section>
      <TopContent>
        <TopLeft>
          <Circle>
            {initials}
            <CamContainer>
              <Cam />
            </CamContainer>
          </Circle>
          <LeftParagraph>
            <BusinessName>
              <LargText>{name}</LargText>
            </BusinessName>
            <Location>
              <LocationSvg /> organization location
            </Location>
          </LeftParagraph>
        </TopLeft>
        <TopRight>
          <Right>
            <StyledButton>View public profile</StyledButton>
          </Right>
        </TopRight>
      </TopContent>
      <ScrollableTabContainer>
        <Tabs defaultActiveKey="1" items={items} style={{ width: "100%" }} />
      </ScrollableTabContainer>
    </Section>
  );
};

export default OrganizerProfile;

import styled from "styled-components";
import { screenSizes } from "ui/theme";

export const CounterSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${screenSizes.laptop}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const BenefitsLeftContainer = styled.div`
  max-width: 560px;
  padding-left: 16px;

  @media (min-width: ${screenSizes.laptop}) {
    padding-left: 88px;
  }
`;
export const BenefitsLeftContainerHeader = styled.h2`
  color: #121212;
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  text-align: left;
`;
export const BenefitsLeftContainerParagraph = styled.p`
  color: #2f2f2f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
`;
export const BenefitsContainerContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
`;
export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;
export const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  color: #475467;
`;
export const BenefitsImage = styled.img`
  width: 768px;
  max-height: 512px;
  border-radius: 10px 0px 0px 0px;
  max-width: 100%;
  height: auto;
  /* align-self: self-end; */

  @media (min-width: ${screenSizes.laptopL}) {
    border-radius: none;
  }
`;

import React, { useEffect, useState, useCallback } from "react";
import { Form, Input, Select } from "antd";
import styled from "styled-components";
import { ReactComponent as Search } from "images/vendor/search.svg";
import SubmitButton from "./Button";
import { getSession } from "lib/utils/utils";
import { useGetUsers, useEditOrganizerByEmail, useGetOrganizerByEmail } from "lib/hooks";
import { nigeriaStatesCities } from "./LocationData";

const { Option } = Select;
const { TextArea } = Input;

const Container = styled.div`
  width: 100%;
`;

const FormBox = styled.div`
  margin-bottom: 10px;
  width: 100%;
`;

const SearchIcon = styled(Search)`
  width: 16px;
  height: 16px;
  color: #9c9c9c;
`;

const Label = styled.div``;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 3rem;
`;

const EnterLocationLink = styled.a`
  font-size: 0.8rem;
  color: #1890ff;
  justify-self: end;
`;

const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const SubmitContainer = styled.div`
  display: flex;
  width: 250px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const EmailInput = styled(Input)`
  background-color: #d9d9d9;
  color: #9c9c9c;
`;

const AccountDetails: React.FC<{ refetchUsers: () => void }> = ({ refetchUsers }) => {
  const [form] = Form.useForm();
  const [userId, setUserId] = useState<number | null>(null);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);

  const { data: usersData, error: usersError } = useGetUsers();
  const { data: organizerData, error: organizerError } = useGetOrganizerByEmail({ email: email });

  const { mutate: editOrganizer, isError, error } = useEditOrganizerByEmail();

  useEffect(() => {
    const session = getSession();
    if (session) {
      setUserId(session.userId);
      setEmail(session.email);
    }
  }, []);

  useEffect(() => {
    if (organizerData) {
      form.setFieldsValue({
        firstName: organizerData.user.firstName,
        lastName: organizerData.user.lastName,
        emailAddress: organizerData.userEmail,
        address: organizerData.address,
        workAddress: organizerData.workAddress,
        country: organizerData.country,
        state: organizerData.state,
        city: organizerData.city,
      });
    }
    if (usersError) {
      console.error("Error fetching users data:", usersError);
    }
  }, [organizerData]);

  useEffect(() => {
    const nigeriaData = nigeriaStatesCities.Nigeria;
    setStates(Object.keys(nigeriaData));
  }, []);

  const handleStateChange = (state: keyof (typeof nigeriaStatesCities)["Nigeria"]) => {
    const selectedCities = nigeriaStatesCities.Nigeria[state];
    setCities(selectedCities);
    form.setFieldsValue({ city: undefined });
  };

  const onFinish = useCallback(
    (values: any) => {
      if (loading) return;

      if (!email) {
        console.error("Email is not set, cannot proceed with the request.");
        return;
      }

      setLoading(true);

      const payload = {
        email: email,
        data: {
          country: values.country,
          state: values.state,
          city: values.city,
          address: values.address,
          workAddress: values.workAddress,
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: "",
            email: email,
          },
        },
      };

      editOrganizer(payload, {
        onSuccess: () => {
          console.log("Organization updated successfully");
          setLoading(false);
          refetchUsers();
        },
        onError: error => {
          console.error("Error updating organization:", error);
          setLoading(false);
        },
      });
    },
    [editOrganizer, email, form, loading],
  );

  return (
    <Container>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ remember: true }} requiredMark={false}>
        <RowContainer>
          <FormBox style={{ flex: 1 }}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: "Please input your first name!" }]}
            >
              <Input placeholder="First Name" style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
            </Form.Item>
          </FormBox>
          <FormBox style={{ flex: 1 }}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: "Please input your last name!" }]}
            >
              <Input placeholder="Last Name" style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
            </Form.Item>
          </FormBox>
        </RowContainer>
        <FormBox>
          <Form.Item
            label="Email Address"
            name="emailAddress"
            rules={[{ required: true, message: "Please input your email address!" }]}
          >
            <EmailInput readOnly style={{ width: "100%", fontSize: "0.8rem" }} />
          </Form.Item>
        </FormBox>

        <RowContainer>
          <FormBox style={{ flex: 1 }}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: "Please input your country!" }]}
            >
              <Select style={{ width: "100%" }}>
                <Option value="Nigeria">Nigeria</Option>
              </Select>
            </Form.Item>
          </FormBox>
          <FormBox style={{ flex: 1 }}>
            <Form.Item label="State" name="state" rules={[{ required: true, message: "Please input your state!" }]}>
              <Select style={{ width: "100%" }} onChange={handleStateChange}>
                {states.map(state => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </FormBox>
          <FormBox style={{ flex: 1 }}>
            <Form.Item label="City" name="city" rules={[{ required: true, message: "Please input your city!" }]}>
              <Select style={{ width: "100%" }}>
                {cities.map(city => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </FormBox>
        </RowContainer>
        <FormBox>
          <Form.Item
            label=" Address"
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
        </FormBox>
        <FormBox>
          <Form.Item
            label="Work Address"
            name="workAddress"
            rules={[{ required: true, message: "Please input your work address!" }]}
          >
            <Input style={{ width: "100%", fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
        </FormBox>

        <SubmitContainer>
          <SubmitButton form={form}>Save changes</SubmitButton>
        </SubmitContainer>
        {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>}
      </Form>
    </Container>
  );
};

export default AccountDetails;

import React from "react";
import { AppFlex, WebsiteContainer } from "ui/components/base";
import { routeNames } from "lib/routes";
import {
  FooterContainer,
  Section,
  SectionA,
  Logo,
  FooterLink,
  Paragraph,
  Social,
  SectionB,
  SocialIcons,
  IconLink,
  Icon,
  FooterColumn,
} from "./footer.style";
import { Row, Col } from "antd";

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <WebsiteContainer>
        <Section>
          <AppFlex>
            <SectionA>
              <Logo src="/LandingPage/footericon.png" alt="Logo" />
              <br />
              <Paragraph>Crafting Event Excellence with Evendy.</Paragraph>
            </SectionA>
          </AppFlex>

          {/*footer links*/}
          <AppFlex>
            <SectionB>
              <Row gutter={[70, 70]}>
                <Col xs={36} sm={12} md={6} lg={6} xl={6}>
                  <FooterColumn>
                    <Paragraph>Company</Paragraph>
                    <FooterLink to="/">Features</FooterLink>
                    <FooterLink to="/">Services</FooterLink>
                    <FooterLink to="/">Tutorials</FooterLink>
                    <FooterLink to={routeNames.contact}>Contact</FooterLink>
                  </FooterColumn>
                </Col>
                <Col xs={36} sm={12} md={6} lg={6} xl={6}>
                  <FooterColumn>
                    <Paragraph>Resources</Paragraph>
                    <FooterLink to="/">Blog</FooterLink>
                    <FooterLink to="/">Newsletter</FooterLink>
                    <FooterLink to="/">Events</FooterLink>
                  </FooterColumn>
                </Col>
                <Col xs={36} sm={12} md={6} lg={6} xl={6}>
                  <FooterColumn>
                    <Paragraph>Legal</Paragraph>
                    <FooterLink to={routeNames.terms}>Terms</FooterLink>
                    <FooterLink to={routeNames.privacy}>Privacy</FooterLink>
                    <FooterLink to="/">Cookies</FooterLink>
                  </FooterColumn>
                </Col>
              </Row>
            </SectionB>
          </AppFlex>
        </Section>

        <Social>
          <Paragraph> © 2024 Evendy. All rights reserved.</Paragraph>
          <SocialIcons>
            <IconLink href="https://x.com/evendyservices" target="_blank" rel="noopener noreferrer">
              <Icon src="/LandingPage/twitterIcon.png" alt="twitter-icon" />
            </IconLink>
            <IconLink href="https://www.linkedin.com/company/evendy" target="_blank" rel="noopener noreferrer">
              <Icon src="/LandingPage/linkdin.png" alt="linkin-icon" />
            </IconLink>
            <IconLink href="https://facebook.com/evendyservices" target="_blank" rel="noopener noreferrer">
              <Icon src="/LandingPage/facebook.png" alt="facebook-icon" />
            </IconLink>
          </SocialIcons>
        </Social>
      </WebsiteContainer>
    </FooterContainer>
  );
};

export default Footer;

import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

// Define the payload type for editing a vendor
type EditVendorPayload = {
  id: number;
  data: {
    businessName?: string;
    businessCategoryName?: string;
    description?: string;
    phone?: string;
    avatar?: string;
    country?: string;
    state?: string;
    city?: string;
    address?: string;
    workAddress?: string;
    kycLevel?: string;
    role?: string;
    accountStatus?: string;
  };
};

// Define the response type
interface EditVendorResponse {
  id: number;
  businessName: string;
  businessCategoryName: string;
  description: string;
  phone: string;
  avatar: string;
  country: string;
  state: string;
  city: string;
  address: string;
  workAddress: string;
  kycLevel: string;
  role: string;
  accountStatus: string;
}

// Define the mutation result type
type UseEditVendorResult = UseMutationResult<EditVendorResponse, Error, EditVendorPayload>;

// Define the useEditVendorById hook
export function useEditVendorById(): UseEditVendorResult {
  return useMutation({
    mutationFn: async (payload: EditVendorPayload) => {
      const { id, data } = payload;
      const res = await EvendyApi.patch<Promise<EditVendorResponse>>(`vendor/${id}`, data);
      return res.data;
    },
    mutationKey: [tqMutationKeys.vendor.edit],
    onError(editError: Error) {
      console.log({ editError });
    },
    onSuccess(data) {
      console.log("Vendor updated successfully", data);
    },
  });
}
